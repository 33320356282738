












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  @Prop() title!: string
  @Prop() subTitle!: string
  @Prop({ default: 'd-flex justify-space-between align-center flex-wrap wrapper' }) classes!: string
  @Prop() goBackAction!: () => void
}
