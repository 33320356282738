import User from '@/shared/modules/user/user.model'
import store from '@/shared/store'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { RoleLevel } from "@/shared/modules/role/role.model";

const roleCan = (roles: RoleLevel[]): boolean => {
  if (!roles?.length) return true;

  const user: User = store.getters[AuthGetters.getUser]
  return roles.includes(user.role.level)
}

export default roleCan
