


































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { CompanyRoutes } from "@/shared/router/company";
import Tab from "@/shared/classes/tab";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";

@Component({
  components: { PageHeader },
  methods: { __, roleCan }
})
export default class HseRoutinesIndex extends Vue {
  roleManagerActive: boolean = roleCan([RoleLevel.manager, RoleLevel.leader])
  tabs = [
    new Tab()
      .setTo(CompanyRoutes.hseRoutinesIndexTabAssigned)
      .setTitle(__('company.views.hse-routines.index.tabs.assigned')),
    new Tab()
      .setTo(CompanyRoutes.hseRoutinesIndexTabTemplates)
      .setTitle(__('company.views.hse-routines.index.tabs.templates'))
      .setAllowedForRoles([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]),
  ]
}
